import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },

    },
    subscribeForm: {
        textAlign: 'center',
        paddingTop: '2%',
        paddingLeft: '2%',
        paddingRight: '2%'
    },
    formInline: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paddingTop: '0%'
    },
    subscribeFormItem: {
        paddingRight: '2%',
    },
    buttonSend: {
        backgroundColor: '#43A6C6',
        fontWeight: 'bold',
        fontSize: '18px',
        '&:hover': {
            backgroundColor: '#2E8BC0',
            color: '#FFF'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '2%'
        },
        marginTop: '.4%'
    },
})

const subscribeForm = props => {

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function ShowMessage({ submitStatus }) {
        if (submitStatus === '') {
            return (
                <p>
                </p>
            )
        } else if (submitStatus) {
            return (
                <Alert severity="success">
                    You have successfully subscribed to the newsletter. Thanks for subscribing!!!
                </Alert>
            )
        }
        else {
            return (
                <Alert severity="error">
                    Form submission unsuccessful. Please try again
                </Alert>
            )
        }
    }

    const message = props.status ? props.status : ''
    const {
        classes,
        values,
        touched,
        errors,
        isSubmitting,
        setStatus,
        status,
        handleChange,
        handleBlur,
        handleSubmit
    } = props;
    return (
        <div>
            {(message === '' || message === false) &&
                <div className={classes.subscribeForm}>
                    <h4 className={classes.heading}
                        style={
                            {
                                marginTop: 0,
                                marginBottom: 3
                            }
                        }>
                        Sign up for Newsletter
                    </h4>
                    <form onSubmit={handleSubmit} className={classes.formInline} id="subscribeForm" name='subscribeForm' >
                        <div className={classes.subscribeFormItem} >
                            <TextField
                                label="Full Name"
                                name="name"
                                id="name"
                                placeholder='Julia Pottinger'
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={touched.name ? errors.name : ""}
                                error={touched.name && Boolean(errors.name)}
                                margin="dense"
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.subscribeFormItem} display="inline" component="div">
                            <TextField
                                label="Email"
                                id="email"
                                type="email"
                                name="email"
                                placeholder='julia@pottinger.com'
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={touched.email ? errors.email : ""}
                                error={touched.email && Boolean(errors.email)}
                                margin="dense"
                                variant="outlined"
                            />
                        </div>
                        <Button type="submit" variant="contained" disabled={isSubmitting} className={classes.buttonSend}>
                            SUBMIT
                </Button>
                    </form>

                </div>
            }
            <div>
                <ShowMessage submitStatus={message} />
            </div>
        </div>
    );
};

const SubForm = withFormik({
    mapPropsToValues: ({
        name,
        email,
    }) => {
        return {
            name: name || "",
            email: email || "",
        };
    },

    validationSchema: Yup.object().shape({
        name: Yup.string()
            .required("Required")
            .min(6, 'FullName must contain at least 6 characters'),
        email: Yup.string()
            .email("Enter a valid email")
            .required("Email is required"),
    }),

    handleSubmit: (values, { status, setStatus, setSubmitting }) => {
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/7646304/d76e5b4a-850b-45ef-ae88-60602922fbcd'
        var data = {
            "fields": [
                {
                    "name": "email",
                    "value": values.email
                },
                {
                    "name": "firstname",
                    "value": values.name
                }
            ],
            "context": {
                "pageUri": "www.juliapottinger.com",
                "pageName": "Julia Pottinger"
            },
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
                setStatus(true)
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                // alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.       
                setStatus(false)
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
                setStatus(false)
            }
        }
        setSubmitting(false);
        // Sends the request 
        xhr.send(final_data)
    }
})(subscribeForm);

export default withStyles(styles)(SubForm);